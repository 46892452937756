import { atomFamily } from 'recoil'

export interface TableStateDefaults {
  page?: number | null
  pageSize?: number | null
  selectedRowIds?: string[]
  search?: string
}

export interface TableState {
  search: string
  page: number
  pageSize: number
  pageCount: number
  canNextPage: boolean
  canPreviousPage: boolean
  selectedRowIds: string[]
}

export const tableState = atomFamily<TableState, string>({
  key: 'TableState',
  default: {
    search: "",
    page: 1,
    pageSize: 10,
    pageCount: 0,
    canNextPage: false,
    canPreviousPage: false,
    selectedRowIds: [],
  },
})
