import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import {
  useFetchTaskByIdQuery,
  TaskStatus,
  useFetchDownloadForTaskLazyQuery,
} from 'api-client'
import { DateTime } from 'luxon'
import { Link, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Button, Indicators } from 'ui'
import toast from 'react-hot-toast'

const parseTimestamp = (timestamp?: string | null) =>
  timestamp
    ? DateTime.fromISO(timestamp).toFormat('h:mm:ss a @ MMM dd, yyyy')
    : ''

export const Task: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>()
  const [fetchDownload] = useFetchDownloadForTaskLazyQuery()
  const { toggleDrawer } = useDrawer()
  const { loading, data } = useFetchTaskByIdQuery({
    variables: {
      id: parseInt(taskId ?? '0'),
    },
    fetchPolicy: 'network-only',
  })
  const [searchParams] = useSearchParams()

  const task = data?.taskById?.task

  const canDownload =
    task?.status === TaskStatus.Complete && task?.name === 'MakeReport'

  const handleDownload = useCallback(async () => {
    const result = await fetchDownload({
      variables: {
        id: parseInt(`${taskId}`),
      },
    })
    const url = result?.data?.downloadForTask?.url
    if (url) {
      window.open(url, '_blank')
      toast.success(`Downloaded report for task: ${taskId}`)
    } else {
      toast.error('Failed to download report.')
    }
  }, [fetchDownload, taskId])

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    if (!initialized) {
      if (searchParams.get('download')) {
        handleDownload()
      }
    }
    setInitialized(true)
  }, [searchParams, handleDownload, initialized, setInitialized])

  return (
    <>
      <Helmet>
        <title>Tasks</title>
      </Helmet>
      <NavBar
        title={`Viewing Task with ID: ${task?.id}`}
        onMenuToggle={toggleDrawer}
      >
        <Profile className="my-auto" />
      </NavBar>
      <Link
        to="/tasks"
        className="ml-8 flex pb-4 font-bold text-accent-default"
      >
        <FontAwesomeIcon className="my-auto" icon={faArrowLeft as IconProp} />
        <span className="mx-2 my-auto underline">View Orders</span>
      </Link>
      <div className="relative mx-8 rounded border border-stone-200 bg-stone-50">
        {loading ? <Indicators.AbsoluteOverlay /> : null}
        <div className="flex flex-col p-4">
          <h2 className="my-2 text-lg font-bold">
            This task was initiated by {task?.createdBy} and took{' '}
            {task?.outputs?.length
              ? DateTime.fromISO(task?.outputs?.[0]?.createdAt ?? '').diff(
                  DateTime.fromISO(
                    task?.outputs?.[task?.outputs?.length - 1]?.createdAt ?? ''
                  ),
                  'seconds'
                ).seconds
              : 0}{' '}
            seconds to complete.
          </h2>
          {canDownload ? (
            <Button onClick={handleDownload} className="mr-auto mb-12">
              Download Report
            </Button>
          ) : null}
          {task?.error ? (
            <p className="rounded border border-red-600 bg-red-100 p-4 text-red-700">
              <span className="block font-bold">
                Job failed with the following response:
              </span>{' '}
              {task.error}
            </p>
          ) : null}
          <p className="my-2 font-bold">
            {task?.outputs?.length
              ? 'Below is a very basic log from this task ran:'
              : "No output was logged during this task's execution."}
          </p>{' '}
          {task?.outputs?.map((output, index) => (
            <article
              key={`${output?.label}${index}`}
              className="my-2 grid grid-cols-4 gap-4 border-t border-stone-200 pt-2"
            >
              <h3 className="col-span-2 font-bold">
                <span className="block">{output?.label}</span>
                <span className="font-normal">{output?.description}</span>
              </h3>
              <p>{output?.value}</p>
              <p>{parseTimestamp(output?.createdAt)}</p>
            </article>
          ))}
        </div>
      </div>
    </>
  )
}
