import React from 'react';
var BASE_BAR = 'flex flex-row border-b border-gray-300 bg-white-100 p-1 relative';
var COMPACT_BAR = "".concat(BASE_BAR, " overflow-visible");
var HIDDEN_BAR = "".concat(BASE_BAR, " h-0 overflow-hidden");
var classForMode = function (mode) {
    switch (mode) {
        case 'compact':
            return COMPACT_BAR;
        case 'hidden':
            return HIDDEN_BAR;
    }
};
export var ToolBar = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? 'compact' : _b, children = _a.children;
    return React.createElement("div", { className: classForMode(mode) }, children);
};
