var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
var defaultOptions = {};
/** Define the type of job to perform on an associated data set. */
export var ProcessJobType;
(function (ProcessJobType) {
    /** Import content area data. */
    ProcessJobType["ImportContentAreas"] = "IMPORT_CONTENT_AREAS";
    /** Import job field data. */
    ProcessJobType["ImportJobs"] = "IMPORT_JOBS";
    /** Import school district data. */
    ProcessJobType["ImportSchools"] = "IMPORT_SCHOOLS";
})(ProcessJobType || (ProcessJobType = {}));
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["Complete"] = "COMPLETE";
    TaskStatus["Failed"] = "FAILED";
    TaskStatus["Pending"] = "PENDING";
    TaskStatus["Running"] = "RUNNING";
})(TaskStatus || (TaskStatus = {}));
/** The destination bucket for an upload. */
export var UploadBucket;
(function (UploadBucket) {
    /** Indicates the file should be uploaded to the private attachments bucket. */
    UploadBucket["Attachments"] = "ATTACHMENTS";
    /** Indicates the file should be uploaded to the public images bucket. */
    UploadBucket["Images"] = "IMAGES";
    /** Indicates the file should be uploaded to the private pending bucket for additional processing. */
    UploadBucket["Pending"] = "PENDING";
})(UploadBucket || (UploadBucket = {}));
export var CreateUploadsDocument = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    mutation CreateUploads($uploadInput: UploadInput!) {\n  response: createUploads(uploadInput: $uploadInput) {\n    uploads {\n      id\n      url\n      downloadUrl\n    }\n    success\n  }\n}\n    "], ["\n    mutation CreateUploads($uploadInput: UploadInput!) {\n  response: createUploads(uploadInput: $uploadInput) {\n    uploads {\n      id\n      url\n      downloadUrl\n    }\n    success\n  }\n}\n    "])));
/**
 * __useCreateUploadsMutation__
 *
 * To run a mutation, you first call `useCreateUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadsMutation, { data, loading, error }] = useCreateUploadsMutation({
 *   variables: {
 *      uploadInput: // value for 'uploadInput'
 *   },
 * });
 */
export function useCreateUploadsMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateUploadsDocument, options);
}
export var ProcessUploadCsvDocument = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation ProcessUploadCSV($fileKey: String!) {\n  processUploadCSV(fileKey: $fileKey) {\n    errors {\n      message\n      path\n    }\n    success\n    fileKey\n    taskId\n  }\n}\n    "], ["\n    mutation ProcessUploadCSV($fileKey: String!) {\n  processUploadCSV(fileKey: $fileKey) {\n    errors {\n      message\n      path\n    }\n    success\n    fileKey\n    taskId\n  }\n}\n    "])));
/**
 * __useProcessUploadCsvMutation__
 *
 * To run a mutation, you first call `useProcessUploadCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessUploadCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processUploadCsvMutation, { data, loading, error }] = useProcessUploadCsvMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function useProcessUploadCsvMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProcessUploadCsvDocument, options);
}
export var DuplicateOrderDocument = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation DuplicateOrder($orderId: String!) {\n  duplicateOrder(orderId: $orderId) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    mutation DuplicateOrder($orderId: String!) {\n  duplicateOrder(orderId: $orderId) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useDuplicateOrderMutation__
 *
 * To run a mutation, you first call `useDuplicateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateOrderMutation, { data, loading, error }] = useDuplicateOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useDuplicateOrderMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DuplicateOrderDocument, options);
}
export var FetchOrderByIdDocument = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query FetchOrderById($id: Int!) {\n  version\n  info\n  orderById(id: $id) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchOrderById($id: Int!) {\n  version\n  info\n  orderById(id: $id) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchOrderByIdQuery__
 *
 * To run a query within a React component, call `useFetchOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOrderByIdQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchOrderByIdDocument, options);
}
export function useFetchOrderByIdLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchOrderByIdDocument, options);
}
export var FetchOrdersDocument = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query FetchOrders($pageInput: PageInput, $search: String) {\n  version\n  info\n  orders(pageInput: $pageInput, search: $search) {\n    orders {\n      id\n      foregroundTextColour\n      backgroundTapeColour\n      customerCode\n      customerOrderRef\n      deliveryName\n      deliveryEstimate\n      productCode\n      importedAt\n      exportedInTask\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchOrders($pageInput: PageInput, $search: String) {\n  version\n  info\n  orders(pageInput: $pageInput, search: $search) {\n    orders {\n      id\n      foregroundTextColour\n      backgroundTapeColour\n      customerCode\n      customerOrderRef\n      deliveryName\n      deliveryEstimate\n      productCode\n      importedAt\n      exportedInTask\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchOrdersQuery__
 *
 * To run a query within a React component, call `useFetchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOrdersQuery({
 *   variables: {
 *      pageInput: // value for 'pageInput'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFetchOrdersQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchOrdersDocument, options);
}
export function useFetchOrdersLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchOrdersDocument, options);
}
export var MakeOrderDocument = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    mutation MakeOrder($orderInput: OrderInput!) {\n  makeOrder(orderInput: $orderInput) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    mutation MakeOrder($orderInput: OrderInput!) {\n  makeOrder(orderInput: $orderInput) {\n    order {\n      id\n      customerCode\n      customerOrderRef\n      productCode\n      quantityRequired\n      backgroundTapeColour\n      foregroundTextColour\n      importedAt\n      emailAddress\n      deliveryMethod\n      deliveryEstimate\n      expressDelivery\n      deliveryPostcode\n      deliveryCountry\n      deliveryAddressLine4\n      deliveryAddressLine3\n      deliveryAddressLine2\n      deliveryAddressLine1\n      line6Text\n      deliveryName\n      line6StyleCode\n      line5Text\n      line5StyleCode\n      line4Text\n      line4StyleCode\n      line3Text\n      line3StyleCode\n      line2Text\n      line2StyleCode\n      line1Text\n      line1StyleCode\n      motifCode\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useMakeOrderMutation__
 *
 * To run a mutation, you first call `useMakeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeOrderMutation, { data, loading, error }] = useMakeOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useMakeOrderMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MakeOrderDocument, options);
}
export var MakeOrderReportDocument = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    mutation MakeOrderReport($orderIds: [String]!, $onlyNewOrders: Boolean) {\n  makeOrderReport(orderIds: $orderIds, onlyNewOrders: $onlyNewOrders) {\n    taskId\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "], ["\n    mutation MakeOrderReport($orderIds: [String]!, $onlyNewOrders: Boolean) {\n  makeOrderReport(orderIds: $orderIds, onlyNewOrders: $onlyNewOrders) {\n    taskId\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "])));
/**
 * __useMakeOrderReportMutation__
 *
 * To run a mutation, you first call `useMakeOrderReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeOrderReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeOrderReportMutation, { data, loading, error }] = useMakeOrderReportMutation({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *      onlyNewOrders: // value for 'onlyNewOrders'
 *   },
 * });
 */
export function useMakeOrderReportMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MakeOrderReportDocument, options);
}
export var FetchDownloadForTaskDocument = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    query FetchDownloadForTask($id: Int!) {\n  version\n  info\n  downloadForTask(id: $id) {\n    url\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchDownloadForTask($id: Int!) {\n  version\n  info\n  downloadForTask(id: $id) {\n    url\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchDownloadForTaskQuery__
 *
 * To run a query within a React component, call `useFetchDownloadForTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDownloadForTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDownloadForTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchDownloadForTaskQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchDownloadForTaskDocument, options);
}
export function useFetchDownloadForTaskLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchDownloadForTaskDocument, options);
}
export var FetchTaskByIdDocument = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    query FetchTaskById($id: Int!) {\n  version\n  info\n  taskById(id: $id) {\n    task {\n      id\n      name\n      status\n      error\n      outputs {\n        label\n        description\n        quantity\n        createdAt\n        value\n      }\n      updatedAt\n      createdAt\n      createdBy\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchTaskById($id: Int!) {\n  version\n  info\n  taskById(id: $id) {\n    task {\n      id\n      name\n      status\n      error\n      outputs {\n        label\n        description\n        quantity\n        createdAt\n        value\n      }\n      updatedAt\n      createdAt\n      createdBy\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchTaskByIdQuery__
 *
 * To run a query within a React component, call `useFetchTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchTaskByIdQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchTaskByIdDocument, options);
}
export function useFetchTaskByIdLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchTaskByIdDocument, options);
}
export var FetchTasksDocument = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    query FetchTasks($pageInput: PageInput) {\n  version\n  info\n  tasks(pageInput: $pageInput) {\n    tasks {\n      id\n      name\n      status\n      updatedAt\n      createdAt\n      createdBy\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchTasks($pageInput: PageInput) {\n  version\n  info\n  tasks(pageInput: $pageInput) {\n    tasks {\n      id\n      name\n      status\n      updatedAt\n      createdAt\n      createdBy\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchTasksQuery__
 *
 * To run a query within a React component, call `useFetchTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTasksQuery({
 *   variables: {
 *      pageInput: // value for 'pageInput'
 *   },
 * });
 */
export function useFetchTasksQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchTasksDocument, options);
}
export function useFetchTasksLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchTasksDocument, options);
}
export var DeleteUserDocument = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation DeleteUser($id: ID!) {\n  deleteUser(id: $id) {\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "], ["\n    mutation DeleteUser($id: ID!) {\n  deleteUser(id: $id) {\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "])));
/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteUserDocument, options);
}
export var FetchUserDocument = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    query FetchUser($id: ID!) {\n  version\n  info\n  userRoles {\n    roles {\n      id\n      name\n      description\n    }\n    success\n  }\n  userById(id: $id) {\n    user {\n      user_id\n      email\n      username\n      given_name\n      family_name\n      logins_count\n      last_ip\n      last_login\n      roles {\n        id\n        name\n        description\n      }\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchUser($id: ID!) {\n  version\n  info\n  userRoles {\n    roles {\n      id\n      name\n      description\n    }\n    success\n  }\n  userById(id: $id) {\n    user {\n      user_id\n      email\n      username\n      given_name\n      family_name\n      logins_count\n      last_ip\n      last_login\n      roles {\n        id\n        name\n        description\n      }\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchUserQuery__
 *
 * To run a query within a React component, call `useFetchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchUserDocument, options);
}
export function useFetchUserLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchUserDocument, options);
}
export var FetchUsersDocument = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    query FetchUsers($pageInput: PageInput) {\n  version\n  info\n  users(pageInput: $pageInput) {\n    users {\n      user_id\n      email\n      last_login\n      logins_count\n      last_ip\n      roles {\n        id\n        name\n        description\n      }\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "], ["\n    query FetchUsers($pageInput: PageInput) {\n  version\n  info\n  users(pageInput: $pageInput) {\n    users {\n      user_id\n      email\n      last_login\n      logins_count\n      last_ip\n      roles {\n        id\n        name\n        description\n      }\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      pages\n      page\n    }\n    success\n    errors {\n      message\n      path\n    }\n  }\n}\n    "])));
/**
 * __useFetchUsersQuery__
 *
 * To run a query within a React component, call `useFetchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersQuery({
 *   variables: {
 *      pageInput: // value for 'pageInput'
 *   },
 * });
 */
export function useFetchUsersQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FetchUsersDocument, options);
}
export function useFetchUsersLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FetchUsersDocument, options);
}
export var UpdateUserRolesDocument = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    mutation UpdateUserRoles($id: ID!, $roleIds: [String]!) {\n  updateUserRoles(id: $id, roleIds: $roleIds) {\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "], ["\n    mutation UpdateUserRoles($id: ID!, $roleIds: [String]!) {\n  updateUserRoles(id: $id, roleIds: $roleIds) {\n    success\n    errors {\n      path\n      message\n    }\n  }\n}\n    "])));
/**
 * __useUpdateUserRolesMutation__
 *
 * To run a mutation, you first call `useUpdateUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRolesMutation, { data, loading, error }] = useUpdateUserRolesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleIds: // value for 'roleIds'
 *   },
 * });
 */
export function useUpdateUserRolesMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateUserRolesDocument, options);
}
var result = {
    "possibleTypes": {}
};
export default result;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
