import React, { useEffect, useRef, useState } from 'react'
import { tableState } from './TableState'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { IconButton } from 'ui'
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSync,
} from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'

const styles = {
  button:
    'rounded bg-accent-default hover:bg-accent-dark p-2 text-white font-bold my-auto mx-1',
}

interface TableControlsProps {
  tableId: string
  onSearch?: (search: string) => void
  onRefetch?: () => void
}

export const TableControls: React.FC<TableControlsProps> = ({
  tableId,
  onSearch: handleSearch,
  onRefetch: handleRefetch,
}) => {
  const [lastPageCount, setLastPageCount] = useState(0)
  const [refreshing, setRefreshing] = useState(false)
  const scopedTableState = tableState(tableId)
  const currentTableState = useRecoilValue(scopedTableState)
  const setTableState = useSetRecoilState(scopedTableState)

  const handlePageSizeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newSize = Number(e.target.value)
      setTableState((state) => ({ ...state, pageSize: newSize, page: 1 }))
    },
    [setTableState]
  )

  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  const handleCurrentPageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const page = e.target.value ? Number(e.target.value) : 0
      const newPage = Math.max(1, Math.min(page, currentTableState.pageCount))
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      timeout.current = setTimeout(() => {
        setTableState((state) => ({ ...state, page: newPage }))
      }, 500)
    },
    [setTableState, currentTableState, timeout]
  )

  const handlePageIncrement = React.useCallback(
    (increment: number) => () => {
      setTableState((state) => ({
        ...state,
        page: currentTableState.page + increment,
      }))
    },
    [setTableState, currentTableState]
  )

  const handleGoToPage = React.useCallback(
    (target: number) => () => {
      setTableState((state) => ({ ...state, page: target }))
    },
    [setTableState]
  )

  useEffect(() => {
    if (
      currentTableState.pageCount > 0 &&
      currentTableState.pageCount !== lastPageCount
    ) {
      setLastPageCount(currentTableState.pageCount)
    }
  }, [currentTableState.pageCount, lastPageCount, setLastPageCount])

  const searchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const search = e.target.value
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }
    searchTimeout.current = setTimeout(() => {
      handleSearch?.(search)
    }, 500)
  }

  const handleRefreshClick = async () => {
    setRefreshing(true)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    await handleRefetch?.()
    setRefreshing(false)
  }

  return (
    <nav className="flex p-2 font-display">
      {handleRefetch ? (
        <IconButton
          icon={faSync}
          onClick={handleRefreshClick}
          disabled={refreshing}
          tooltip="Refresh"
          className={clsx(styles.button, 'mr-8')}
        />
      ) : null}
      <IconButton
        icon={faChevronDoubleLeft}
        onClick={handleGoToPage(1)}
        disabled={!currentTableState.canPreviousPage}
        tooltip="First page"
        className={styles.button}
      />
      <IconButton
        icon={faChevronLeft}
        onClick={handlePageIncrement(-1)}
        disabled={!currentTableState.canPreviousPage}
        tooltip="Previous page"
        className={styles.button}
      />
      <IconButton
        icon={faChevronRight}
        onClick={handlePageIncrement(1)}
        disabled={!currentTableState.canNextPage}
        tooltip="Next page"
        className={styles.button}
      />
      <IconButton
        icon={faChevronDoubleRight}
        onClick={handleGoToPage(currentTableState.pageCount)}
        disabled={!currentTableState.canNextPage}
        tooltip="Last page"
        className={styles.button}
      />
      {handleSearch ? (
        <input
          type="search"
          placeholder="search"
          className="mx-2 my-auto flex-grow rounded-sm border border-stone-300 p-2"
          onChange={handleSearchChange}
        />
      ) : (
        <span className="flex flex-grow">&nbsp;</span>
      )}
      <span className="mx-6 my-auto">
        Page{' '}
        <strong>
          {currentTableState.page} of{' '}
          {currentTableState.pageCount || lastPageCount}
        </strong>{' '}
      </span>
      <span className="my-auto">Go to page: </span>{' '}
      <input
        type="number"
        defaultValue={currentTableState.page}
        onChange={handleCurrentPageChange}
        className="my-auto mx-2 w-16 rounded-sm border border-stone-300 p-2"
      />
      <select
        value={currentTableState.pageSize}
        onChange={handlePageSizeChange}
        className="my-auto mx-2 rounded-sm border border-stone-300 p-2 accent-primary-default"
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </nav>
  )
}
