import React from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { useFetchOrderByIdQuery, useMakeOrderMutation, useDuplicateOrderMutation } from 'api-client'
import { Button, OrderForm, OrderFormValues } from 'ui'
import { FormErrors } from '@sumocreations/forms'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import toast from 'react-hot-toast'

export const Order: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>()
  const { toggleDrawer } = useDrawer()
  const { loading, data } = useFetchOrderByIdQuery({
    variables: {
      id: parseInt(orderId ?? '0'),
    },
  })
  const navigate = useNavigate()
  const [makeOrder] = useMakeOrderMutation()
  const [duplicateOrder, {loading: duplicating}] = useDuplicateOrderMutation()

  const handleSubmit = async (values: OrderFormValues) => {
    const { __typename, deliveryEstimateField, ...orderInput } = values as any
    const order = await makeOrder({
      variables: { orderInput: { ...orderInput, id: `${orderInput.id}` } },
    })
    if (order.errors) {
      return (order.data?.makeOrder?.errors ?? []) as unknown as FormErrors
    }
    return undefined
  }

  const handleDuplicate = async () => {
    const order = await duplicateOrder({variables: {orderId: orderId ?? '0'}})
    toast.success(`Order ${order.data?.duplicateOrder?.order?.customerOrderRef} created`)
    navigate(`/orders/${order.data?.duplicateOrder?.order?.id}`)
  }

  return (
    <>
      <Helmet>
        <title>Order</title>
      </Helmet>
      <NavBar
        title={`Viewing Order: ${data?.orderById?.order?.customerOrderRef}`}
        onMenuToggle={toggleDrawer}
      >
        <Profile className="my-auto" />
      </NavBar>
      <div className='flex flex-row mx-8 pb-4'>
      <Link
        to="/orders"
        className="flex font-bold text-accent-default"
      >
        <FontAwesomeIcon className="my-auto" icon={faArrowLeft as IconProp} />
        <span className="mx-2 my-auto underline">View Orders</span>
      </Link>
      <div className='flex flex-grow'>&nbsp;</div>
      <Button onClick={handleDuplicate} className={duplicating ? "opacity-50 pointer-events-none" : undefined}>Create Duplicate</Button>
      </div>
      <div className="relative mx-8 rounded border border-stone-200 bg-stone-50">
        <OrderForm
          defaultValues={(data?.orderById?.order as any) ?? undefined}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}
