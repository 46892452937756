import React, { HTMLProps } from 'react'
import clsx from 'clsx'

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean
}

export const IndeterminateCheckbox = React.forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef<HTMLInputElement | null>(null)
  const resolvedRef = (ref ||
    defaultRef) as React.MutableRefObject<HTMLInputElement | null>

  React.useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate ?? false
    }
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        {...rest}
        className={clsx(
          rest.className,
          indeterminate ? 'accent-white' : 'accent-accent-default'
        )}
      />
    </>
  )
})
