import React, { useMemo } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate, Link } from 'react-router-dom'
import {
  useFetchOrdersQuery,
  Order,
  useMakeOrderReportMutation,
} from 'api-client'
import { Table, Column } from './Table'
import { Button, IconButton, Indicators } from 'ui'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useManagedTableState } from './useTableState'
import { parseTimestamp } from './helpers'

const ORDERS_TABLE_ID = 'orders'

const fakeResult: Order = {
  id: '------',
  deliveryName: '------',
  deliveryEstimate: '------',
  importedAt: '----',
  customerOrderRef: '----',
}

export const Orders: React.FC = () => {
  const navigate = useNavigate()
  const { toggleDrawer } = useDrawer()
  const [makeOrderReport, { loading: loadingOrderReport }] =
    useMakeOrderReportMutation()

  const {
    currentTableState,
    handleSelection,
    clearSelection,
    updatePagination,
    handleSearch
  } = useManagedTableState({
    tableId: ORDERS_TABLE_ID,
  })

  const { data, loading, refetch } = useFetchOrdersQuery({
    variables: {
      pageInput: {
        page: currentTableState.page,
        pageSize: currentTableState.pageSize,
      },
      search: currentTableState.search,
    },
  })

  const results = data?.orders?.orders ?? []
  const pageCount = data?.orders?.pageInfo?.pages ?? 0
  const canNextPage = data?.orders?.pageInfo?.hasNextPage ?? false
  const canPreviousPage = data?.orders?.pageInfo?.hasPreviousPage ?? false

  updatePagination({
    pageCount,
    canNextPage,
    canPreviousPage,
  })

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'deliveryName',
        private: true
      },
      {
        Header: 'Code',
        accessor: 'customerCode',
      },
      {
        Header: 'Reference',
        accessor: 'customerOrderRef',
      },
      {
        Header: 'Product',
        accessor: 'productCode',
      },
      {
        Header: 'Est. Delivery',
        accessor: 'deliveryEstimate',
      },
      {
        Header: 'Imported',
        accessor: 'importedAt',
      },
      {
        Header: 'Exported',
        accessor: 'exported',
      },
    ],
    []
  )

  const handleClearSelection = () => {
    clearSelection()
  }

  const handleEditRow = (id: string) => {
    navigate(`/orders/${id}`)
  }
  const selectedCount = currentTableState?.selectedRowIds.length ?? 0

  const handleExport = (exportAll?: boolean) => async () => {
    const result = await makeOrderReport({
      variables: {
        orderIds: exportAll ? [] : currentTableState.selectedRowIds,
        onlyNewOrders: exportAll,
      },
    })
    if (result?.data?.makeOrderReport?.success) {
      toast.success('Report job initialized.')
      setTimeout(() => {
        navigate(
          `/tasks?highlighted=${
            result?.data?.makeOrderReport?.taskId ?? 0
          }&page=1`
        )
      }, 1000)
    } else {
      toast.error('Failed to create report job.')
    }
  }


  const handleRefetch = async () => {
    await refetch()
  }

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <NavBar title="Orders" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <Button 
        className='ml-auto mr-8 flex' 
        onClick={handleExport(true)} 
        disabled={loadingOrderReport}>
            {loadingOrderReport ? (
              <Indicators.Spinner className="mr-2" />
            ) : null}{' '}Export All New Orders
      </Button>
      <Table
        data={
          !loading
            ? results.map((result) => ({
                ...result,
                importedAt: parseTimestamp(result?.importedAt),
                deliveryEstimate: parseTimestamp(result?.deliveryEstimate),
                exported: result?.exportedInTask ? <Link to={`/tasks/${result?.exportedInTask}`}>✅ View</Link> : "⚠️",
              }))
            : [...Array(currentTableState.pageSize)].map(() => fakeResult)
        }
        columns={columns}
        controlledPageCount={pageCount}
        loading={loading}
        tableId={ORDERS_TABLE_ID}
        onSelect={handleSelection}
        onEdit={handleEditRow}
        onRefetch={handleRefetch}
        onSearch={handleSearch}
      />
      <div
        className={clsx(
          'fixed inset-x-0 bottom-0 z-20 flex w-full transition-all duration-300 ease-in-out',
          selectedCount < 1 && 'translate-y-24 opacity-0'
        )}
      >
        <footer className="relative flex flex-grow rounded border border-stone-100 bg-white p-2 shadow-md">
          <IconButton
            tooltip="Clear selection"
            toolTipDirection="above"
            icon={faTimes}
            onClick={handleClearSelection}
            className="my-auto mr-2 bg-accent-default text-white hover:bg-accent-dark"
          />
          <h3 className="mr-2 flex items-center rounded-full bg-primary-default/10 px-4 text-center align-middle font-bold text-primary-dark">
            {selectedCount} Orders Selected
          </h3>
          <Button
            className="ml-auto mr-1 flex"
            onClick={handleExport(false)}
            disabled={loadingOrderReport}
          >
            {loadingOrderReport ? (
              <Indicators.Spinner className="mr-2" />
            ) : null}{' '}
            Make Report
          </Button>
        </footer>
      </div>
    </>
  )
}
