var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import { Icon } from './Icon';
import { faChevronLeft, faChevronRight, } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
export var MiniCal = function (_a) {
    var _b, _c;
    var initialDate = _a.initialDate, selectedDates = _a.selectedDates, handleSelectedDates = _a.onDateSelected, _d = _a.monthsToShow, monthsToShow = _d === void 0 ? 1 : _d, className = _a.className, range = _a.range, _e = _a.rangeDirection, rangeDirection = _e === void 0 ? 'both' : _e;
    var _f = useState(null), hoverDate = _f[0], setHoverDate = _f[1];
    var _g = useState([]), trackedDates = _g[0], setTrackedDates = _g[1];
    var _h = useState(0), increment = _h[0], setIncrement = _h[1];
    var handleIncrementClick = function (newIncrement) {
        return function (e) {
            e.preventDefault();
            setIncrement(increment + newIncrement);
        };
    };
    useEffect(function () {
        setIncrement(0);
    }, [selectedDates, initialDate]);
    var originDate = (_c = (_b = selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[0]) !== null && _b !== void 0 ? _b : initialDate) !== null && _c !== void 0 ? _c : DateTime.utc();
    var monthStart = (originDate.isValid ? originDate : DateTime.utc())
        .startOf('month')
        .plus({ months: increment });
    var handleDateClick = function (date) { return function (e) {
        e.preventDefault();
        e.stopPropagation();
        setTrackedDates(range && trackedDates.length < 2 ? __spreadArray(__spreadArray([], trackedDates, true), [date], false) : [date]);
    }; };
    useEffect(function () {
        var _a;
        if (trackedDates.length === 2 || (!range && trackedDates.length === 1)) {
            var diff = trackedDates[0]
                .diff((_a = trackedDates[1]) !== null && _a !== void 0 ? _a : trackedDates[0])
                .as('days');
            handleSelectedDates === null || handleSelectedDates === void 0 ? void 0 : handleSelectedDates(trackedDates, diff < 1 ? 'forward' : 'backward');
        }
    }, [trackedDates, handleSelectedDates, range]);
    var gridClassNames = monthsToShow > 2
        ? 'grid grid-cols-3 gap-4'
        : monthsToShow > 1
            ? 'grid grid-cols-2 gap-4'
            : null;
    return (React.createElement("div", { className: "h-full p-2 px-4" },
        React.createElement("div", { className: "relative flex flex-col" },
            React.createElement("nav", { className: "absolute inset-x-0 top-0 flex rounded p-2" },
                React.createElement("button", { onClick: handleIncrementClick(-1), className: "absolute left-0 top-0 p-2" },
                    React.createElement(Icon, { icon: faChevronLeft, mode: "secondary" })),
                React.createElement("button", { onClick: handleIncrementClick(1), className: "absolute top-0 right-0 p-2" },
                    React.createElement(Icon, { icon: faChevronRight, mode: "secondary" }))),
            React.createElement("div", { className: clsx(gridClassNames, 'flex-grow') }, __spreadArray([], Array(monthsToShow), true).map(function (_, months) { return (React.createElement("div", { className: clsx('flex flex-col', className), key: "month-".concat(months) },
                React.createElement("h3", { className: "mx-auto flex-grow p-2 text-center text-sm font-semibold" }, monthStart.plus({ months: months }).toFormat('MMM, yyyy')),
                new Array(6).fill('').map(function (_, weeks) {
                    return (React.createElement("div", { className: "grid grid-cols-7", key: "minical-week-".concat(weeks) }, new Array(7).fill('').map(function (_, days) {
                        var _a, _b, _c, _d;
                        var currentMonth = monthStart
                            .plus({ months: months })
                            .startOf('month');
                        var day = currentMonth
                            .plus({ weeks: weeks })
                            .startOf('week')
                            .plus({ days: days - 1 });
                        var inMonth = currentMonth.month === day.month;
                        var isIncompleteRange = range && trackedDates.length === 1;
                        var isBefore = ((_a = trackedDates[0]) === null || _a === void 0 ? void 0 : _a.diff(day).as('days')) > 0;
                        var disabled = !inMonth ||
                            (isIncompleteRange &&
                                !((_b = trackedDates[0]) === null || _b === void 0 ? void 0 : _b.equals(day)) &&
                                ((rangeDirection === 'forward' && isBefore) ||
                                    (rangeDirection === 'backward' && !isBefore)));
                        var isSelectedDate = (_c = trackedDates[0]) === null || _c === void 0 ? void 0 : _c.equals(day);
                        var start = (_d = trackedDates[0]) !== null && _d !== void 0 ? _d : selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[0];
                        var end = (selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates.length) === 2 && trackedDates.length < 1
                            ? selectedDates === null || selectedDates === void 0 ? void 0 : selectedDates[1]
                            : hoverDate;
                        var rangeValues = [start, end].sort();
                        var isInRange = range &&
                            rangeValues[0] &&
                            rangeValues[1] &&
                            Interval.fromDateTimes(rangeValues[0], rangeValues[1].plus({ day: 1 })).contains(day);
                        return (React.createElement("li", { className: clsx('flex border-2 text-xs transition duration-100 ease-out', disabled
                                ? 'pointer-events-none cursor-not-allowed border-transparent bg-gray-100 text-gray-400'
                                : (start === null || start === void 0 ? void 0 : start.equals(day))
                                    ? 'border-accent-dark bg-white text-accent-dark'
                                    : (end === null || end === void 0 ? void 0 : end.equals(day))
                                        ? 'border-primary-dark bg-white text-primary-dark'
                                        : isSelectedDate || isInRange
                                            ? 'bg-primary-default text-white hover:bg-primary-dark'
                                            : 'bg-gray-100 text-gray-800 hover:bg-gray-300'), key: "date-".concat(months, "-").concat(weeks, "-").concat(days), onMouseOver: function () { return setHoverDate(day); } },
                            React.createElement("button", { className: "flex flex-grow p-1", onClick: handleDateClick(day.toUTC()) },
                                React.createElement("span", { className: "m-auto" }, day.toFormat('d')))));
                    })));
                }))); })))));
};
