import React, { useEffect } from 'react'
import { useAuth0, User } from '@auth0/auth0-react'
import { Button, Indicators } from 'ui'
import clsx from 'clsx'
import LogRocket from "logrocket"
interface ProfileProps {
  className?: string
  style?: React.CSSProperties
}

const Profile: React.FC<ProfileProps> = ({ style, className }) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const [identifiedUser, setIdentifiedUser] = React.useState<User | null>(null)
  useEffect(() => {
    if(identifiedUser?.sub !== user?.sub && user) {
      LogRocket.identify(user.sub ?? 'n/a', {
        name: user?.name ?? user.nickname ?? 'n/a',
        email: user?.email ?? 'n/a',
        picture: user?.picture ?? 'n/a',
      });
      setIdentifiedUser(user) 
    }
  }, [user, identifiedUser, setIdentifiedUser])

  if (isLoading) {
    return <Indicators.Spinner />
  }

  const handleLoginClick: React.MouseEventHandler = (e) => {
    e.preventDefault()
    loginWithRedirect()
  }

  return isAuthenticated ? (
    <button
      className={clsx('relative h-8 w-8 rounded-full', className)}
      style={style}
    >
      <img
        src={user?.picture}
        alt={user?.name}
        className="absolute inset-0 rounded-full"
      />
    </button>
  ) : (
    <Button
      disabled={isLoading}
      onClick={handleLoginClick}
      className={className}
      style={style}
    >
      Login
    </Button>
  )
}

export default Profile
