import { DateTime } from 'luxon'

export const parseTimestamp = (timestamp?: string | null) =>
  timestamp ? DateTime.fromISO(timestamp).toFormat('MMM d, yyyy') : ''

export const parseTimestampRelative = (timestamp?: string | null) =>
  timestamp ? DateTime.fromISO(timestamp).toRelative() : ''

export const getOptionalValue = (val?: string | null) =>
  val ? parseInt(val) : undefined
