import React, { useState } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPencil } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  useFetchUserQuery,
  UserRole,
  useUpdateUserRolesMutation,
  useDeleteUserMutation,
} from 'api-client'
import { DateTime } from 'luxon'
import {
  IconButton,
  Indicators,
  MultiSelect,
  DropDownListItemType,
  Button,
  TextField,
} from 'ui'
import clsx from 'clsx'

interface DangerFormProps {
  onCancel?: () => void
  onSubmit: () => void
  confirmText: string
  promptText: string
  className?: string
}

const DangerForm: React.FC<DangerFormProps> = ({
  onCancel,
  onSubmit,
  confirmText,
  promptText,
  className,
}) => {
  const [confirm, setConfirm] = useState('')
  const onChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirm(event.target.value)
  }

  return (
    <form className={clsx('flex flex-col rounded bg-stone-200 p-4', className)}>
      <TextField
        label={promptText}
        name="confirm"
        onChange={onChangeEventHandler}
        placeholder="type 'delete'"
      />
      <Button
        onClick={onSubmit}
        disabled={confirm !== 'delete'}
        appearance="destructive"
        className="mt-4"
      >
        {confirmText}
      </Button>
      {onCancel ? (
        <Button appearance="link" onClick={onCancel} className="my-4 mx-auto">
          Do Not Delete
        </Button>
      ) : null}
    </form>
  )
}

interface RoleMiniFormProps {
  roles: UserRole[]
  initialValue: UserRole[]
  onCancel: () => void
  onSubmit: (roles: UserRole[]) => void
}

const RoleMiniForm: React.FC<RoleMiniFormProps> = ({
  onCancel,
  onSubmit,
  roles,
  initialValue,
}) => {
  const [editingRoles, setEditingRoles] = useState(
    initialValue.map((r) => r.id).join(' ')
  )
  const options = roles.map(
    (r): DropDownListItemType => ({
      id: r?.id ?? '',
      label: r?.name ?? '',
      secondaryLabel: r?.description ?? '',
      value: r?.id ?? '',
    })
  )

  const handleSubmit = () => {
    const newRoles =
      editingRoles.split(' ').map((id) => roles.find((r) => r?.id === id)) ?? []
    onSubmit(newRoles as UserRole[])
  }

  return (
    <div className="m-2 flex flex-col border border-stone-200 p-2">
      <MultiSelect
        name="roles"
        label="Updated Roles"
        options={options}
        value={editingRoles}
        onSelect={(value) => {
          console.log('value', value)
          setEditingRoles(value)
        }}
      />
      <div className="flex flex-col">
        <Button className="my-2" onClick={handleSubmit}>
          Update Roles
        </Button>
        <Button appearance="link" className="mx-auto" onClick={onCancel}>
          Leave Roles as They Were
        </Button>
      </div>
    </div>
  )
}

export const User: React.FC = () => {
  const { toggleDrawer } = useDrawer()
  const { userId } = useParams<{ userId: string }>()
  const {
    loading,
    data,
    refetch: refreshUser,
  } = useFetchUserQuery({
    variables: {
      id: userId ?? '',
    },
  })
  const [deleteUser] = useDeleteUserMutation()
  const navigate = useNavigate()
  const [updateRoles, { loading: updatingRoles }] = useUpdateUserRolesMutation()
  const [editRoles, setEditRoles] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const user = data?.userById?.user
  const roles = data?.userRoles?.roles ?? []

  const handleRolesSubmit = async (roles: UserRole[]) => {
    await updateRoles({
      variables: {
        id: user?.user_id ?? '',
        roleIds: roles.map((r) => r?.id ?? ''),
      },
    })
    setEditRoles(false)
    await new Promise((resolve) => setTimeout(resolve, 1500))
    await refreshUser()
  }

  return (
    <>
      <Helmet>
        <title>Viewing User</title>
      </Helmet>
      <NavBar title="User" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <Link
        to="/users"
        className="ml-8 flex pb-4 font-bold text-accent-default"
      >
        <FontAwesomeIcon className="my-auto" icon={faArrowLeft as IconProp} />
        <span className="mx-2 my-auto underline">View Users</span>
      </Link>
      <div className="relative mx-8 flex flex-col rounded border border-stone-200 bg-stone-50 p-4">
        {loading || updatingRoles ? <Indicators.AbsoluteOverlay /> : null}
        <h2 className="m-2 flex flex-col">
          <span className="font-bold">Email</span>
          {user?.email ?? '------------'}
        </h2>
        <p className="m-2 flex flex-col">
          <span className="font-bold">Last Login</span>
          {loading
            ? '-------------'
            : DateTime.fromISO(user?.last_login ?? '').toRelative()}
          {loading ? null : (
            <span className="text-xs font-bold text-primary-dark">{` (IP Address: ${user?.last_ip})`}</span>
          )}
        </p>
        <div className="m-2 mr-auto flex w-full max-w-md flex-col rounded bg-white p-2 shadow">
          <h3 className="font-bold">Roles</h3>
          {editRoles ? (
            <RoleMiniForm
              roles={roles as UserRole[]}
              initialValue={(user?.roles ?? []) as UserRole[]}
              onCancel={() => setEditRoles(false)}
              onSubmit={handleRolesSubmit}
            />
          ) : (
            <>
              <p className="mb-3">
                Roles determine what this user can perform within the order
                management system.
              </p>
              <ul className="flex flex-col">
                {user?.roles?.map((role) => (
                  <li
                    key={role?.id}
                    className="my-2 mr-auto rounded bg-stone-100 p-2 text-sm"
                  >
                    <span className="rounded bg-primary-default  p-1 font-bold text-white">
                      {role?.name}:
                    </span>{' '}
                    <span>{role?.description}</span>
                  </li>
                )) ?? '------------'}
              </ul>
              <IconButton
                icon={faPencil}
                tooltip="Edit Roles"
                className="ml-auto bg-accent-default text-white hover:bg-accent-default/25 hover:text-accent-dark"
                onClick={() => setEditRoles(true)}
              />
            </>
          )}
        </div>
        {isDeleting ? (
          <DangerForm
            promptText="Do you want to delete this user?"
            confirmText="Delete User"
            className="mt-4 ml-auto max-w-md"
            onSubmit={async () => {
              await deleteUser({
                variables: {
                  id: user?.user_id ?? '',
                },
              })
              navigate('/users')
            }}
            onCancel={() => {
              setIsDeleting(false)
            }}
          />
        ) : (
          <Button
            appearance="destructive"
            className="ml-auto"
            onClick={() => setIsDeleting(true)}
          >
            Delete User
          </Button>
        )}
      </div>
    </>
  )
}
