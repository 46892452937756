var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { concatStyles } from 'utils';
var LIST_STYLE = 'flex flex-col flex-grow';
var TOP_LEVEL_LIST_STYLE = 'max-w-full';
var NESTED_LIST_STYLE = '-m-2 sm:flex-row';
var NESTED_COL_STYLE = '-m-2';
var ITEM_STYLE = 'flex flex-grow flex-shrink-0 p-2';
var RESPONSIVE_GROWTH_STYLE = 'sm:flex-grow-0';
export var Fields = function (_a) {
    var className = _a.className, children = _a.children, nested = _a.nested, strictVertical = _a.strictVertical, grow = _a.grow, register = _a.register, errors = _a.errors;
    return (React.createElement("ul", { className: concatStyles([
            LIST_STYLE,
            nested
                ? strictVertical
                    ? NESTED_COL_STYLE
                    : NESTED_LIST_STYLE
                : TOP_LEVEL_LIST_STYLE,
            grow ? '' : RESPONSIVE_GROWTH_STYLE,
            className,
        ]) }, React.Children.map(children, function (child, index) {
        var _a, _b, _c, _d, _e, _f;
        return child ? (React.createElement("li", { className: concatStyles([
                (child === null || child === void 0 ? void 0 : child.props.type) === 'hidden' ? 'w-0 h-0' : ITEM_STYLE,
                (child === null || child === void 0 ? void 0 : child.props.type) !== 'hidden' && grow
                    ? ''
                    : RESPONSIVE_GROWTH_STYLE,
            ]) }, ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.name) || ((_b = child === null || child === void 0 ? void 0 : child.props) === null || _b === void 0 ? void 0 : _b.nested)
            ? React.createElement(child.type, __assign({}, __assign(__assign(__assign({}, child.props), { register: register, errors: errors, key: "child".concat(child.type).concat(index).concat((_d = (_c = child === null || child === void 0 ? void 0 : child.props) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '') }), (((_e = child === null || child === void 0 ? void 0 : child.props) === null || _e === void 0 ? void 0 : _e.name) ? register((_f = child === null || child === void 0 ? void 0 : child.props) === null || _f === void 0 ? void 0 : _f.name) : {}))))
            : React.createElement(child.type, __assign({}, __assign(__assign({}, child.props), { key: "child".concat(child.type).concat(index) }))))) : null;
    })));
};
Fields.displayName = 'Form.Fields';
