import React from 'react'
import ReactDOM from 'react-dom'
import 'ui/lib/labelweavers-ui.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { UIProvider } from 'ui'
import { Auth0Provider } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthorizedApolloProvider } from './AuthorizedApolloProvider'
import { Home } from './Home'
import { Orders } from './Orders'
import { Order } from './Order'
import { Tasks } from './Tasks'
import { Task } from './Task'
import { Users } from './Users'
import { User } from './User'
import { Import } from './Import'
import LogRocket from 'logrocket';

LogRocket.init('rngp6b/labelweavers-management');

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
    >
      <RecoilRoot>
        <AuthorizedApolloProvider>
          <UIProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<App />}>
                  <Route index element={<Home />} />
                  <Route path="import" element={<Import />} />
                  <Route path="tasks">
                    <Route index element={<Tasks />} />
                    <Route path=":taskId" element={<Task />} />
                  </Route>
                  <Route path="orders">
                    <Route index element={<Orders />} />
                    <Route path=":orderId" element={<Order />} />
                  </Route>
                  <Route path="users">
                    <Route index element={<Users />} />
                    <Route path=":userId" element={<User />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </UIProvider>
        </AuthorizedApolloProvider>
      </RecoilRoot>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
