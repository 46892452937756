import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
var styles = {
    container: 'fixed left-0 top-0 w-96 bg-accent-default flex flex-col pt-4 md:pt-12 px-4 pb-4 z-40 h-screen text-white',
    title: 'flex text-2xl font-display font-semibold mb-8 ml-8',
    button: 'w-12 h-12 mr-2 flex hover:bg-accent-dark rounded-full',
    icon: 'm-auto',
    overlay: 'fixed top-0 left-0 w-screen h-screen z-30 bg-primary-default bg-opacity-10',
};
export var Drawer = function (_a) {
    var title = _a.title, open = _a.open, children = _a.children, onDismiss = _a.onDismiss;
    var handleDismiss = function (e) {
        e.preventDefault();
        e.stopPropagation();
        onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss();
    };
    var handleDrawerClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    return open ? (React.createElement("div", { className: styles.overlay, onClick: handleDismiss },
        React.createElement("nav", { className: styles.container, onClick: handleDrawerClick },
            React.createElement("h1", { className: styles.title },
                React.createElement("button", { className: styles.button, onClick: handleDismiss },
                    React.createElement(FontAwesomeIcon, { icon: faTimes, className: styles.icon })),
                ' ',
                title ? React.createElement("span", { className: "my-auto ml-6" }, title) : null),
            children))) : null;
};
