import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var BASE_ICON = 'flex m-auto';
var DEFAULT_ICON = "".concat(BASE_ICON, " text-current");
var PRIMARY_ICON = "".concat(BASE_ICON, " text-accent-default");
var SECONDARY_ICON = "".concat(BASE_ICON, " text-primary-default hover:text-primary-dark");
var NEGATIVE_ICON = "".concat(BASE_ICON, " text-white");
var classForMode = function (mode) {
    switch (mode) {
        case 'primary':
            return PRIMARY_ICON;
        case 'secondary':
            return SECONDARY_ICON;
        case 'negative':
            return NEGATIVE_ICON;
        default:
            return DEFAULT_ICON;
    }
};
export var Icon = function (_a) {
    var icon = _a.icon, mode = _a.mode;
    return (React.createElement(FontAwesomeIcon, { className: classForMode(mode), icon: icon }));
};
