import React, { useMemo } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { usePermissions } from './usePermissions'
import { Button, Indicators } from 'ui'
import { Link, useNavigate } from 'react-router-dom'
import { useFetchOrdersQuery, useFetchTasksQuery, TaskStatus } from 'api-client'
import { Table, Column } from './Table'
import { DateTime } from 'luxon'

const parseTimestamp = (timestamp?: string | null) =>
  timestamp ? DateTime.fromISO(timestamp).toFormat('MMM d, yyyy') : ''

export const Home: React.FC = () => {
  const navigate = useNavigate()
  const { toggleDrawer } = useDrawer()
  const { permissions, isLoading, isAuthenticated } = usePermissions()

  const { data: tasksData, loading: tasksLoading } = useFetchTasksQuery()
  const tasksColumns = useMemo<Column[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
      },
    ],
    []
  )
  const tasks = tasksData?.tasks?.tasks ?? []

  const { data: ordersData, loading: ordersLoading } = useFetchOrdersQuery()
  const ordersColumns = useMemo<Column[]>(
    () => [
      {
        Header: 'Reference',
        accessor: 'customerOrderRef',
      },
      {
        Header: 'Name',
        accessor: 'deliveryName',
      },
      {
        Header: 'Est. Delivery',
        accessor: 'deliveryEstimate',
      },
    ],
    []
  )
  const orders = ordersData?.orders?.orders ?? []

  const getIcon = (status?: TaskStatus) => {
    switch (status) {
      case TaskStatus.Pending:
        return '🕑'
      case TaskStatus.Running:
        return '▶️'
      case TaskStatus.Complete:
        return '✅'
      case TaskStatus.Failed:
        return '⛔️'
      default:
        return '🤨'
    }
  }

  return (
    <>
      <Helmet>
        <title>Order Management Dashboard</title>
      </Helmet>
      <NavBar title="Order Management Dashboard" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <div className="relative mx-8 flex flex-col">
        {isLoading || !isAuthenticated ? <Indicators.AbsoluteOverlay /> : null}
        {isAuthenticated && permissions.length === 0 ? (
          <h3 className="border border-stone-200 bg-stone-100 p-4 text-lg font-bold text-red-400">
            You do not have permission to use this system. Please request
            permission from an administrator.
          </h3>
        ) : null}
        <div className="grid grid-cols-2 gap-4">
          {isAuthenticated && permissions?.includes('manage:orders') ? (
            <div className="flex flex-col">
              <Table
                data={
                  !ordersLoading
                    ? orders.map((result) => ({
                        ...result,
                        importedAt: parseTimestamp(result?.importedAt),
                        deliveryEstimate: parseTimestamp(
                          result?.deliveryEstimate
                        ),
                      }))
                    : []
                }
                columns={ordersColumns}
                controlledPageCount={1}
                loading={ordersLoading}
                tableId={'ORDERS_PREVIEW_TABLE_ID'}
                hideControls
              />
              <Button className="mx-8 mt-4" onClick={() => navigate('/orders')}>
                View All Orders
              </Button>
            </div>
          ) : null}
          {isAuthenticated && permissions?.includes('manage:orders') ? (
            <div className="flex flex-col">
              <Table
                data={
                  !tasksLoading
                    ? tasks.map((result) => ({
                        ...result,
                        name: (
                          <>
                            <Link
                              to={`/tasks/${result?.id}`}
                              className="text-shadow-sm font-bold text-accent-default underline drop-shadow-sm"
                            >
                              {result?.name}
                            </Link>
                          </>
                        ),
                        status: `${getIcon(result?.status as TaskStatus)} ${
                          result?.status
                        }`,
                        updatedAt: parseTimestamp(result?.updatedAt),
                      }))
                    : []
                }
                columns={tasksColumns}
                controlledPageCount={1}
                loading={tasksLoading}
                tableId={'TASKS_PREVIEW_TABLE_ID'}
                hideControls
              />
              <Button className="mx-8 mt-4" onClick={() => navigate('/orders')}>
                View All Tasks
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
