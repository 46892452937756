import React from 'react'
import clsx from 'clsx'
import { IconButton, Indicators } from 'ui'
import { useTableState } from './useTableState'
import { faPencil } from '@fortawesome/pro-solid-svg-icons'
import { TableControls } from './TableControls'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'

export interface Column {
  Header: string
  accessor: string
  private?: boolean
}

interface TableProps {
  columns: Column[]
  data: any[]
  controlledPageCount?: number
  initialPageSize?: number
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  loading?: boolean
  tableId: string
  selectedIds?: string[]
  onSelect?: (ids: string[], selected: boolean) => void
  onEdit?: (id: string) => void
  onSearch?: (search: string) => void
  onRefetch?: () => void
  highlightedRows?: string[]
  hideControls?: boolean
}

const styles = {
  tableWrap: 'my-3 flex rounded-lg border border-stone-200 bg-stone-50 p-2',
  table:
    'flex-grow table-auto border-collapse rounded border border-stone-300 bg-white font-display',
  header: 'border border-stone-200 bg-primary-default p-1 font-bold text-white',
  thead: 'sticky top-0 z-10 bg-white',
  row: 'border border-stone-200 py-1 px-2 transition-colors duration-200 ease-in-out',
  rowAlt: 'bg-stone-50',
  rowSelected: 'bg-primary-default/70 text-white',
  rowSelectedAlt: 'bg-primary-dark/75 text-white',
  controlWrap: 'border border-stone-300',
  loading: 'pointer-events-none relative opacity-50',
  highlightRow: 'bg-yellow-400/50 text-black font-bold animate-pulse',
}

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  loading,
  tableId,
  onSelect: handleRowSelection,
  onEdit: handleRowEdit,
  onSearch: handleSearch,
  highlightedRows,
  hideControls,
  onRefetch: handleRefetch,
}) => {
  const { currentTableState } = useTableState({
    tableId,
  })

  const hasSelected =
    data
      ?.map((d) => d.id)
      .filter((id) => currentTableState?.selectedRowIds?.includes(id))
      ?.length ?? 0
  const hasSelectedAll = hasSelected === data?.length
  const handleSelectAll = () => {
    handleRowSelection?.(
      data.map((row) => row.id),
      !hasSelectedAll
    )
  }
  const colCount =
    columns.length + (handleRowSelection ? 1 : 0) + (handleRowEdit ? 1 : 0)

  return (
    <div className={clsx('mx-8 flex flex-col', loading && styles.loading)}>
      {loading && (
        <div className="fixed inset-x-0 top-0 min-h-screen">
          <Indicators.AbsoluteOverlay />
        </div>
      )}

      <div className={styles.tableWrap}>
        <table className={styles.table}>
          <thead className={styles.thead}>
            {hideControls ? null : (
              <tr>
                <td colSpan={colCount} className={styles.controlWrap}>
                  <TableControls
                    tableId={tableId}
                    onSearch={handleSearch}
                    onRefetch={handleRefetch}
                  />
                </td>
              </tr>
            )}
            <tr>
              {handleRowSelection ? (
                <th className={styles.header}>
                  <IndeterminateCheckbox
                    onChange={handleSelectAll}
                    checked={hasSelected > 0}
                    indeterminate={hasSelected > 0 && !hasSelectedAll}
                  />
                </th>
              ) : null}
              {columns.map((column) => (
                <th key={column.accessor} className={styles.header}>
                  {column.Header}
                </th>
              ))}
              {handleRowEdit ? <th className={styles.header}>Edit</th> : null}
            </tr>
          </thead>

          <tbody>
            {data.map((row, i) => {
              const isSelected = currentTableState.selectedRowIds.includes(
                row.id
              )
              const isHighlighted = highlightedRows?.includes(`${row.id}`)
              const rowClassNames = clsx(
                styles.row,
                isHighlighted && styles.highlightRow,
                i % 2 && isSelected && styles.rowSelectedAlt,
                i % 2 && !isSelected && styles.rowAlt,
                isSelected && styles.rowSelected
              )

              return (
                <tr key={`row-${i}`}>
                  {handleRowSelection ? (
                    <td
                      className={clsx('text-center', styles.row, rowClassNames)}
                    >
                      <IndeterminateCheckbox
                        checked={isSelected}
                        onChange={() =>
                          handleRowSelection?.([row.id], !isSelected)
                        }
                      />
                    </td>
                  ) : null}
                  {columns.map((col, ii) => {
                    return (
                      <td
                        key={`${i}-${ii}`}
                        className={clsx('text-left', rowClassNames)}
                        {...(col.private ? { "data-private": true } : {})}
                      >
                        {row[col.accessor]}
                      </td>
                    )
                  })}
                  {handleRowEdit ? (
                    <td
                      className={clsx('text-center', styles.row, rowClassNames)}
                    >
                      <IconButton
                        icon={faPencil}
                        tooltip="Edit"
                        onClick={() => handleRowEdit?.(row.id)}
                      />
                    </td>
                  ) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
