import React, { useState } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import {
  useCreateUploadsMutation,
  UploadBucket,
  useProcessUploadCsvMutation,
} from 'api-client'
import {
  AttachmentList,
  Button,
  FileToEndpointFn,
  HandleAssociatedFileUpdateFn,
  HandleDeleteFn,
  HandleFileUploadFn,
  Indicators,
  MimeTypes,
} from 'ui'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

export const Import: React.FC = () => {
  const navigate = useNavigate()
  const { toggleDrawer } = useDrawer()
  const [attachments, setAttachments] = useState([] as any)
  const [makeUpload] = useCreateUploadsMutation()
  const [processUploadCsvMutation, { loading, data }] =
    useProcessUploadCsvMutation()

  const makeEndpoints: FileToEndpointFn = async (filenames) => {
    const result = await makeUpload({
      variables: {
        uploadInput: {
          objects: filenames.map(({ name: key, contentType }) => ({
            key,
            contentType,
          })),
          bucket: UploadBucket.Pending,
        },
      },
    })
    const endpoints = (result.data?.response?.uploads ?? []).map((upload) => ({
      uploadUrl: upload?.url ?? '',
      downloadUrl: upload?.downloadUrl ?? '',
      id: upload?.id ?? '',
    }))
    return endpoints ?? [{}]
  }

  const handleAttachments: HandleAssociatedFileUpdateFn = (files) => {
    setAttachments(
      files.map((f) => ({
        ...f,
        id: f.id,
        position: f.position,
        name: f.file?.name ?? f.name ?? f.id,
      }))
    )
  }

  const handleDeletedFile: HandleDeleteFn = (file) => {
    console.log(`Deleted file: ${file.id}`)
  }

  const handleUploadedFile: HandleFileUploadFn = (file) => {
    console.log(`Finished uploading file: ${file.id}`)
  }

  const handleProcess: React.MouseEventHandler = async (e) => {
    e.preventDefault()
    const result = await processUploadCsvMutation({
      variables: {
        fileKey: attachments[0].id,
      },
    })
    toast.success('Your file is being processed...')
    await new Promise((resolve) => setTimeout(resolve, 1000))
    navigate(`/tasks?highlighted=${result.data?.processUploadCSV.taskId ?? ''}`)
  }
  return (
    <>
      <Helmet>
        <title>Import Data</title>
      </Helmet>
      <NavBar title="Import Data" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <div className="relative ml-20 flex flex-1 px-8">
        {loading ? <Indicators.AbsoluteOverlay /> : null}
        <div className="flex w-1/2 max-w-xl flex-col">
          <h2 className="my-2 font-display text-lg font-bold">
            Convert Order Customizations
          </h2>
          <p className="text-md my-2 font-display">
            Use the tool below to automatically extract order customizations
            from an unshipped orders report on Amazon Seller Central.
          </p>
          <section className="mt-6 flex flex-col">
            <AttachmentList
              accept={MimeTypes.TXT}
              getEndpointsForFilenames={makeEndpoints}
              previews={attachments}
              onUpdate={handleAttachments}
              onDelete={handleDeletedFile}
              onUpload={handleUploadedFile}
              label="Drag and Drop Amazon CSV Here"
            />
            <Button
              className="mt-6"
              disabled={attachments.length < 1}
              onClick={handleProcess}
            >
              Process File
            </Button>
          </section>
        </div>
        {data?.processUploadCSV ? (
          <div className="flex w-1/2 flex-col">
            <p className="font-monospace overflow-x- flex rounded bg-primary-default p-4 text-white">
              <Indicators.Spinner /> <span>One moment...</span>
            </p>
          </div>
        ) : null}
      </div>
    </>
  )
}
