import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var styles = {
    button: 'cursor-pointer rounded flex text-white text-lg font-semibold p-2 font-display w-full m-0 hover:bg-black hover:bg-opacity-5 transition-colors duration-250 ease-out',
    icon: 'mx-auto',
    iconWrap: 'flex w-24 p-0 mx-0 my-auto',
    text: 'ml-3 mr-auto',
};
export var DrawerItem = function (_a) {
    var name = _a.name, icon = _a.icon, onClick = _a.onClick;
    var handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    return (React.createElement("button", { onClick: handleClick, disabled: !onClick, className: styles.button },
        React.createElement("p", { className: styles.iconWrap },
            React.createElement(FontAwesomeIcon, { icon: icon, className: styles.icon })),
        React.createElement("span", { className: styles.text }, name)));
};
