import React from 'react';
import clsx from 'clsx';
var BASE_BAR = 'flex flex-col relative';
var EXPANDED_BAR = "".concat(BASE_BAR, " overflow-visible w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5");
var COMPACT_BAR = "".concat(BASE_BAR, " overflow-visible w-auto");
var HIDDEN_BAR = "".concat(BASE_BAR, " w-0 overflow-hidden");
var classForMode = function (mode) {
    switch (mode) {
        case 'compact':
            return COMPACT_BAR;
        case 'expanded':
            return EXPANDED_BAR;
        default:
            return HIDDEN_BAR;
    }
};
var borderForAlignment = function (alignment) {
    return alignment === 'left' ? 'border-r' : 'border-l';
};
var colorsForAppearance = function (appearance) {
    return appearance === 'navigator'
        ? 'border-gray-300 bg-primary-default'
        : 'border-gray-300 bg-gray-100';
};
export var SideBar = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? 'compact' : _b, _c = _a.appearance, appearance = _c === void 0 ? 'default' : _c, _d = _a.alignment, alignment = _d === void 0 ? 'right' : _d, children = _a.children, renderHeading = _a.renderHeading, className = _a.className, style = _a.style;
    return (React.createElement("div", { className: clsx([
            classForMode(mode),
            borderForAlignment(alignment),
            colorsForAppearance(appearance),
            className,
        ]), style: style },
        renderHeading ? (React.createElement("div", { className: "flex w-full mb-1" }, renderHeading())) : null,
        React.createElement("nav", { className: "".concat(BASE_BAR, " p-1 h-full") }, children)));
};
