import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Drawer, DrawerItem, Indicators, Dialog } from 'ui'
import logo from './logo.png'
import {
  faClock,
  faUpload,
  faFileInvoice,
  faUsers,
  faSignOut,
  faHome,
} from '@fortawesome/pro-regular-svg-icons'
import { useDrawer } from './useDrawer'
import { Toaster } from 'react-hot-toast'
import { usePermissions } from './usePermissions'

const App: React.FC = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, logout, user } =
    useAuth0()
  const { closeDrawer, isOpen } = useDrawer()
  const navigate = useNavigate()
  const { permissions } = usePermissions()

  console.log(
    'error',
    error,
    'isAuthenticated',
    isAuthenticated,
    'isLoading',
    isLoading,
    'user',
    user
  )

  useEffect(() => {
    if (!isAuthenticated && isOpen) {
      closeDrawer()
    }
  }, [isAuthenticated, isOpen, closeDrawer])

  if (isLoading) {
    return <Indicators.AbsoluteOverlay />
  }

  const handleLogout = () => {
    logout()
  }
  const handleErrorConfirm = () => {
    loginWithRedirect()
  }

  const navigateTo = (path: string) => () => {
    navigate(path)
    closeDrawer()
  }

  return (
    <div className="relative flex min-h-screen w-full flex-col pb-20">
      {isAuthenticated && <Outlet />}
      {isAuthenticated && (
        <>
          <Drawer onDismiss={closeDrawer} open={isOpen} title="Menu">
            <DrawerItem
              icon={faHome}
              name="Dashboard"
              onClick={navigateTo('/')}
            />
            {permissions.includes('manage:orders') ? (
              <>
                <DrawerItem
                  icon={faUpload}
                  name="Import Data"
                  onClick={navigateTo('/import')}
                />
                <DrawerItem
                  icon={faFileInvoice}
                  name="Orders"
                  onClick={navigateTo('/orders')}
                />
                <DrawerItem
                  icon={faClock}
                  name="Tasks"
                  onClick={navigateTo('/tasks')}
                />
              </>
            ) : null}
            {permissions.includes('manage:users') ? (
              <DrawerItem
                icon={faUsers}
                name="Users"
                onClick={navigateTo('/users')}
              />
            ) : null}
            <div className="mt-auto" />
            <DrawerItem icon={faSignOut} name="Logout" onClick={handleLogout} />
          </Drawer>
        </>
      )}
      {!isAuthenticated && !isLoading ? (
        <Dialog
          open={true}
          title="Please Sign In"
          message={`You must login or create an account to use the order management system. If you create an account you will still need to be granted access by a system administrator.`}
          confirmLabel="Login / Signup"
          onConfirm={handleErrorConfirm}
        />
      ) : null}
      <Link
        to="/"
        className="absolute bottom-0 right-0 z-10 mr-8 mb-8 block h-auto w-36"
      >
        <img src={logo} alt="Label Weavers" />
      </Link>
      <Toaster />
    </div>
  )
}

export default App
