import React, { useState } from 'react';
import { Icon } from '../Form';
import { ToolTip } from './ToolTip';
import clsx from 'clsx';
var BUTTON_ITEM = 'relative text-primary-default border border-primary-default text-xs lg:text-sm px-1 lg:px-2 my-auto h-8 xl:h-10 first:rounded-l-lg first:border-r-0 last:rounded-r-lg last:border-l-0 hover:bg-gray-200';
var BASE_ITEM = 'outline-none active:outline-none border-0 my-auto h-8 w-8 lg:w-10 lg:h-10 hover:bg-gray-200 rounded-full relative transition ease-out duration-200 flex-shrink-0';
var DISABLED_ITEM = "opacity-50 cursor-not-allowed pointer-events-none";
var NEGATIVE_INDICATED_ITEM = "".concat(BASE_ITEM, " bg-gray-200 hover:bg-gray-300");
var PRIMARY_INDICATED_ITEM = "".concat(BASE_ITEM, " bg-accent-default hover:bg-accent-default-dark");
var SECONDARY_INDICATED_ITEM = "".concat(BASE_ITEM, " bg-primary-default hover:bg-primary-dark");
var styleForItem = function (_a) {
    var indicated = _a.indicated, disabled = _a.disabled, indicatorMode = _a.indicatorMode, appearance = _a.appearance;
    if (appearance === 'button') {
        return BUTTON_ITEM;
    }
    var baseClass = BASE_ITEM;
    if (indicated) {
        switch (indicatorMode) {
            case 'primary':
                baseClass = PRIMARY_INDICATED_ITEM;
                break;
            case 'secondary':
                baseClass = SECONDARY_INDICATED_ITEM;
                break;
            default:
                baseClass = NEGATIVE_INDICATED_ITEM;
                break;
        }
    }
    return clsx(baseClass, disabled && DISABLED_ITEM);
};
export var BarItem = function (_a) {
    var children = _a.children, className = _a.className, disabled = _a.disabled, onClick = _a.onClick, icon = _a.icon, label = _a.label, toolTip = _a.toolTip, toolTipDirection = _a.toolTipDirection, toolTipAlignment = _a.toolTipAlignment, shortCut = _a.shortCut, _b = _a.mode, mode = _b === void 0 ? 'primary' : _b, indicated = _a.indicated, _c = _a.indicatorMode, indicatorMode = _c === void 0 ? 'negative' : _c, _d = _a.appearance, appearance = _d === void 0 ? 'icon' : _d;
    var _e = useState(false), hover = _e[0], setHover = _e[1];
    var handleClick = function (e) {
        e.preventDefault();
        if (!disabled) {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }
    };
    var isButton = appearance === 'button';
    return (React.createElement("button", { onClick: handleClick, style: isButton ? {} : { outlineColor: 'rgba(0,0,0,0)' }, onMouseOver: function () { return setHover(true); }, onMouseOut: function () { return setHover(false); }, className: clsx(!disabled && 'cursor-pointer', styleForItem({ indicated: indicated, disabled: disabled, indicatorMode: indicatorMode, appearance: appearance }), className) },
        icon ? (React.createElement("div", { className: clsx('m-auto flex', appearance === 'button'
                ? 'text-md px-1'
                : 'text-md lg:text-md xl:text-xl') },
            React.createElement(Icon, { icon: icon, mode: mode }))) : label ? (React.createElement("span", { className: "m-auto" }, label)) : (React.createElement("div", { className: "m-auto flex" }, children)),
        toolTip ? (React.createElement(ToolTip, { label: toolTip, direction: toolTipDirection, align: toolTipAlignment, active: hover, shortCut: shortCut })) : null));
};
