import React, { useMemo } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { Link, useSearchParams } from 'react-router-dom'
import { useFetchTasksQuery, Task, TaskStatus } from 'api-client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Table, Column } from './Table'
import { parseTimestampRelative } from './helpers'
import { useManagedTableState } from './useTableState'

const TASKS_TABLE_ID = 'tasks'

const fakeResult: Task = {
  id: '------',
  name: '------',
  status: TaskStatus.Pending,
  createdAt: '----',
  updatedAt: '----',
}

export const Tasks: React.FC = () => {
  const { toggleDrawer } = useDrawer()
  const [searchParams] = useSearchParams()

  const { currentTableState, updatePagination } = useManagedTableState({
    tableId: TASKS_TABLE_ID,
  })

  const { data, loading, refetch } = useFetchTasksQuery({
    variables: {
      pageInput: {
        page: currentTableState.page,
        pageSize: currentTableState.pageSize,
      },
    },
    fetchPolicy: 'network-only',
  })

  const results = data?.tasks?.tasks ?? []
  const pageCount = data?.tasks?.pageInfo?.pages ?? 0
  const canNextPage = data?.tasks?.pageInfo?.hasNextPage ?? false
  const canPreviousPage = data?.tasks?.pageInfo?.hasPreviousPage ?? false

  const handleRefetch = async () => {
    await refetch()
  }

  updatePagination({
    pageCount,
    canNextPage,
    canPreviousPage,
  })

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
    ],
    []
  )

  const getIcon = (status?: TaskStatus) => {
    switch (status) {
      case TaskStatus.Pending:
        return '🕑'
      case TaskStatus.Running:
        return '▶️'
      case TaskStatus.Complete:
        return '✅'
      case TaskStatus.Failed:
        return '⛔️'
      default:
        return '🤨'
    }
  }

  const canDownload = (task: Task) =>
    task.status === TaskStatus.Complete && task.name === 'MakeReport'

  return (
    <>
      <Helmet>
        <title>Tasks</title>
      </Helmet>
      <NavBar title="Tasks" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <Table
        data={
          !loading
            ? results.map((result: any) => ({
                ...result,
                name: (
                  <>
                    {canDownload(result) ? (
                      <Link
                        to={`${result?.id}?download=true`}
                        className="mr-4 aspect-square w-8 rounded-full bg-accent-default p-1 text-sm text-white hover:bg-accent-dark"
                      >
                        <FontAwesomeIcon icon={faCloudDownload as IconProp} />
                      </Link>
                    ) : null}
                    <Link
                      to={result?.id}
                      className="text-shadow-sm font-bold text-accent-default underline drop-shadow-sm"
                    >
                      {result?.name}
                    </Link>
                  </>
                ),
                status: `${getIcon(result?.status as TaskStatus)} ${
                  result?.status
                }`,
                updatedAt: parseTimestampRelative(result?.updatedAt),
                createdAt: parseTimestampRelative(result?.createdAt),
              }))
            : [...Array(currentTableState.pageSize)].map(() => fakeResult)
        }
        highlightedRows={searchParams.get('highlighted')?.split(' ') ?? []}
        columns={columns}
        controlledPageCount={pageCount}
        loading={loading}
        tableId={TASKS_TABLE_ID}
        onRefetch={handleRefetch}
      />
    </>
  )
}
