import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface NavBarProps {
  onMenuToggle: () => void
  title: string
}

export const NavBar: React.FC<NavBarProps> = ({
  onMenuToggle: handleMenuToggle,
  children,
  title,
}) => {
  const handleMenuClick: React.MouseEventHandler = e => {
    e.preventDefault()
    handleMenuToggle()
  }

  return (
    <header className="flex">
      <nav className="flex flex-grow flex-row p-8">
        <button
          onClick={handleMenuClick}
          className="my-auto mr-8 aspect-square h-12 w-12 rounded-full p-1 text-2xl text-accent-default transition-colors duration-500 ease-in-out hover:bg-stone-100"
        >
          <FontAwesomeIcon icon={faBars as IconProp} className="m-auto" />
        </button>
        <h1 className="my-auto mr-auto font-display text-2xl font-bold">
          {title}
        </h1>
        {children}
      </nav>
    </header>
  )
}
