var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DragSource, DropTarget, } from 'react-dnd';
import { FileUploadStatus, } from './FileDropProps';
import { FileUpload } from './FileUpload';
import { PreviewAction } from './PreviewAction';
import { Progress } from './Progress';
export var DND_TYPE_FILEPREVIEW = 'DND.ImagePreview';
export var Thumbnail = React.forwardRef(function (_a, ref) {
    var primary = _a.primary, children = _a.children, props = __rest(_a, ["primary", "children"]);
    return (React.createElement("li", __assign({}, props, { ref: ref, className: clsx('border-4', primary ? 'border-accent-default' : 'border-primary-dark'), style: {
            minWidth: 110,
        } }), children));
});
var Preview = React.forwardRef(function (_a, ref) {
    var canDrop = _a.canDrop, primary = _a.primary, preview = _a.preview, isDragging = _a.isDragging, connectDragSource = _a.connectDragSource, connectDropTarget = _a.connectDropTarget, uploadURL = _a.uploadURL, onComplete = _a.onComplete, onDelete = _a.onDelete;
    var _b = useState(0), progress = _b[0], setProgress = _b[1];
    var _c = useState(false), hovering = _c[0], setHovering = _c[1];
    var _d = useState(null), hoverTimeout = _d[0], setHoverTimeout = _d[1];
    var _e = useState(null), localPreview = _e[0], setLocalPreview = _e[1];
    var _f = useState(null), upload = _f[0], setUpload = _f[1];
    var elementRef = useRef(null);
    if (!localPreview && preview && preview.file) {
        setLocalPreview(URL.createObjectURL(preview.file));
    }
    if (!upload && preview && preview.file) {
        setUpload(FileUpload(preview.id, preview.file, uploadURL, function (id, status, fileProgress) {
            setProgress(fileProgress);
            if (status === FileUploadStatus.Available && onComplete) {
                onComplete(__assign(__assign({}, preview), { status: FileUploadStatus.Available }));
            }
        }));
    }
    useEffect(function () { return function () {
        if (localPreview) {
            URL.revokeObjectURL(localPreview);
        }
        if (upload) {
            upload.cancel();
        }
    }; }, [localPreview, upload]);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    useImperativeHandle(ref, function () { return ({
        getNode: function () { return elementRef.current; },
    }); });
    var opacity = isDragging ? 0.5 : 1;
    var transmitting = preview.status === FileUploadStatus.Pending;
    var deletePreview = function (e) {
        if (onDelete) {
            onDelete(preview);
        }
        e.stopPropagation();
    };
    var toggleHover = function (enabled) { return function () {
        if (hoverTimeout && enabled) {
            clearTimeout(hoverTimeout);
        }
        if (!enabled) {
            setHoverTimeout(setTimeout(function () { return setHovering(enabled); }, 25));
        }
        else {
            setHovering(enabled);
        }
    }; };
    var deleteVisible = !canDrop && hovering ? true : false;
    return (React.createElement(Thumbnail, { ref: elementRef, style: { opacity: opacity }, onMouseOver: toggleHover(true), onMouseOut: toggleHover(false), onMouseDown: toggleHover(false), primary: primary },
        onDelete ? (React.createElement(PreviewAction, { onClick: deletePreview, onMouseOver: toggleHover(true), visible: deleteVisible },
            React.createElement(FontAwesomeIcon, { icon: faTrash }))) : (React.createElement("span", null)),
        preview ? (React.createElement("img", { src: localPreview || preview.downloadUrl || '', className: clsx(transmitting && 'pointer-events-none opacity-20', 'flex') })) : null,
        transmitting ? React.createElement(Progress, { progress: progress }) : null));
});
export var ImagePreview = DropTarget(DND_TYPE_FILEPREVIEW, {
    hover: function (props, monitor, component) {
        if (!component) {
            return null;
        }
        // node = HTML Div element from imperative API
        var node = component.getNode();
        if (!node) {
            return null;
        }
        var dragIndex = monitor.getItem().index;
        var hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Determine rectangle on screen
        var hoverBoundingRect = node.getBoundingClientRect();
        // Get vertical middle
        var hoverMiddle = {
            x: (hoverBoundingRect.right - hoverBoundingRect.left) / 2,
            y: (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
        };
        // Determine mouse position
        var clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        var hoverClientX = clientOffset.x - hoverBoundingRect.left;
        // Only perform the move when the mouse has crossed half of the items width
        // When dragging rightwards, only move when the cursor is to the right of 50%
        // When dragging leftwards, only move when the cursor is left of 50%
        // Dragging rightwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddle.x) {
            return;
        }
        // Dragging leftwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddle.x) {
            return;
        }
        // Time to actually perform the action
        props.onSort(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
}, function (connect, monitor) { return ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
}); })(DragSource(DND_TYPE_FILEPREVIEW, {
    beginDrag: function (props) { return ({
        id: props.id,
        index: props.index,
    }); },
    endDrag: function (props) {
        props.onFinishSort();
    },
}, function (connect, monitor) { return ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
}); })(Preview));
