import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import decode from 'jwt-decode'

export const usePermissions = () => {
  const {
    isLoading,
    error,
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0()
  const [permissions, setPermissions] = useState([] as string[])

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      if (token) {
        const output = decode(token) as any
        if (permissions.join('') !== output?.permissions?.join('')) {
          setPermissions(output?.permissions ?? [])
        }
      }
    }
    if (isAuthenticated) {
      getToken()
    }
  }, [isAuthenticated, getAccessTokenSilently, permissions, setPermissions])

  return {
    isLoading,
    permissions,
    error,
    isAuthenticated,
  }
}
