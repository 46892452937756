import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

const API_URL = process.env.REACT_APP_API_URL ?? ''

const fetcher = (input: RequestInfo, init?: RequestInit | undefined) => {
  return window.fetch(input, init)
}

export const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  /**
   * The base configuration for the client to access the remote API.
   */
  const httpLink = createHttpLink({
    uri: `${API_URL}`,
    fetch: fetcher,
  })

  /**
   * Assigns any available access token as the authorization header
   * for any outgoing request.
   */
  const setAuthorizationLink = setContext((_, previousContext) => ({
    headers: {
      Authorization: previousContext.token
        ? `Bearer ${previousContext.token}`
        : null,
    },
  }))

  /**
   * Fetches a cached token or refreshes the current credentials
   * if necessary.
   */
  const asyncAuthLink = setContext(
    _ =>
      new Promise(async success => {
        const token = await getAccessTokenSilently()
        success({ token })
      })
  )

  /**
   * The client with our final cache / link configuration.
   */
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: asyncAuthLink.concat(setAuthorizationLink).concat(httpLink),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
