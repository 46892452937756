import { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from './useLocalStorage'
import { tableState } from './TableState'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useSearchParams } from 'react-router-dom'
import { getOptionalValue } from './helpers'

export const useTableState = ({ tableId }: { tableId: string }) => ({
  currentTableState: useRecoilValue(tableState(tableId)),
  setTableState: useSetRecoilState(tableState(tableId)),
})

export const useManagedTableState = ({ tableId }: { tableId: string }) => {
  const { currentTableState, setTableState } = useTableState({ tableId })
  const [selectedIds, setSelectedIds] = useLocalStorage<string[]>(
    `selectedIdsFor${tableId}`,
    []
  )
  const [storedParams, setStoredParams] = useLocalStorage<{
    pageSize: string
    page: string
  }>(`storedParams-${tableId}`, { pageSize: '10', page: '1' })
  const [searchParams, setSearchParams] = useSearchParams(storedParams)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized) {
      setTableState(state => ({
        ...state,
        selectedRowIds: selectedIds,
        pageSize:
          getOptionalValue(searchParams.get('pageSize')) ??
          currentTableState.pageSize,
        page:
          getOptionalValue(searchParams.get('page')) ?? currentTableState.page,
      }))
      setInitialized(true)
    }
  }, [
    initialized,
    setInitialized,
    setTableState,
    currentTableState,
    selectedIds,
    searchParams,
  ])

  useEffect(() => {
    if (
      initialized &&
      (`${currentTableState.pageSize}` !== storedParams.pageSize ||
        `${currentTableState.page}` !== storedParams.page)
    ) {
      const newParams = {
        page: `${currentTableState.page}`,
        pageSize: `${currentTableState.pageSize}`,
      }
      setSearchParams(newParams)
      setStoredParams(newParams)
    }
  }, [
    initialized,
    currentTableState.page,
    currentTableState.pageSize,
    storedParams.page,
    storedParams.pageSize,
    setSearchParams,
    setStoredParams,
  ])

  // useEffect(() => {
  //   if (
  //     currentTableState.pageCount !== pages ||
  //     currentTableState.canNextPage !== hasNextPage ||
  //     currentTableState.canPreviousPage !== hasPreviousPage
  //   ) {
  //     setTableState(state => ({
  //       ...state,
  //       ...state,
  //       pageCount: pages,
  //       canNextPage: hasNextPage,
  //       canPreviousPage: hasPreviousPage,
  //     }))
  //   }
  // }, [setTableState, currentTableState, pages, hasNextPage, hasPreviousPage])

  const handleSelection = (ids: string[], selected: boolean) => {
    const selectedRowIds = selected
      ? [...currentTableState.selectedRowIds, ...ids]
      : currentTableState.selectedRowIds.filter(id => !ids.includes(id))
    setTableState(state => ({
      ...state,
      selectedRowIds,
    }))
    setSelectedIds(selectedRowIds)
  }

  const clearSelection = () =>
    setTableState(state => ({
      ...state,
      selectedRowIds: [],
    }))

  const updatePagination = useCallback(
    (params: {
      canNextPage?: boolean
      canPreviousPage?: boolean
      page?: number
      pageCount?: number
      pageSize?: number
    }) => {
      if (
        currentTableState.canNextPage !==
          (params.canNextPage ?? currentTableState.canNextPage) ||
        currentTableState.canPreviousPage !==
          (params.canPreviousPage ?? currentTableState.canPreviousPage) ||
        currentTableState.page !== (params.page ?? currentTableState.page) ||
        currentTableState.pageSize !==
          (params.pageSize ?? currentTableState.pageSize) ||
        currentTableState.pageCount !==
          (params.pageCount ?? currentTableState.pageCount)
      ) {
        setTableState(state => ({
          ...state,
          ...params,
        }))
      }
    },
    [currentTableState, setTableState]
  )

  const handleSearch = useCallback((search: string) => {
    console.log("Searching....", search, "<- was ->", currentTableState.search, currentTableState)
    if (
      currentTableState.search !== search) {
      setTableState(state => ({
        ...state,
        page: 1,
        search,
      }))
    }
  }, [setTableState, currentTableState])

  return {
    currentTableState,
    handleSelection,
    clearSelection,
    updatePagination,
    handleSearch
  }
}
