import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolTip } from './ToolTip';
import clsx from 'clsx';
var BUTTON_STYLES = 'rounded-full w-10 flex-shrink-0';
var HOVER_STYLES = 'hover:bg-gray-400 hover:bg-opacity-25';
var INACTIVE_STYLES = 'opacity-50';
var DISABLED_STYLES = 'opacity-25 pointer-events-none';
export var IconButton = function (_a) {
    var disabled = _a.disabled, icon = _a.icon, clickHandlerFromProps = _a.onClick, className = _a.className, style = _a.style, tooltip = _a.tooltip, _b = _a.tooltipAlignment, tooltipAlignment = _b === void 0 ? 'center' : _b, _c = _a.toolTipDirection, toolTipDirection = _c === void 0 ? 'below' : _c, inactive = _a.inactive, noPadding = _a.noPadding;
    var _d = useState(), hoverTimeout = _d[0], setHoverTimeout = _d[1];
    var _e = useState(false), hover = _e[0], setHover = _e[1];
    var handleMouseOver = function (e) {
        if (tooltip) {
            setHoverTimeout(setTimeout(function () { return setHover(true); }, 500));
        }
    };
    var handleMouseOut = function (e) {
        if (tooltip) {
            clearTimeout(hoverTimeout);
            setHover(false);
        }
    };
    var handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (clickHandlerFromProps) {
            clickHandlerFromProps(e.currentTarget);
        }
    };
    return (React.createElement("button", { className: clsx([
            BUTTON_STYLES,
            (className !== null && className !== void 0 ? className : '').indexOf('absolute') < 0 && 'relative',
            (className !== null && className !== void 0 ? className : '').indexOf('hover:bg') < 0 && HOVER_STYLES,
            !noPadding && 'p-2',
            className,
            disabled ? DISABLED_STYLES : inactive ? INACTIVE_STYLES : null,
        ]), onClick: handleClick, style: style, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseOut },
        React.createElement(FontAwesomeIcon, { icon: icon }),
        tooltip ? (React.createElement(ToolTip, { label: tooltip, active: hover, direction: toolTipDirection, align: tooltipAlignment })) : null));
};
