var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef } from 'react';
import { Button, TextField, Fields, DateField } from '../Form';
import { AbsoluteOverlay } from '../Indicators';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDefaultValueListener, } from '@sumocreations/forms';
import { camelCase } from 'lodash';
import { ErrorList } from '../Form/ErrorList';
import { DateTime } from 'luxon';
var styles = {
    header: 'text-md font-semibold text-primary-dark mt-6 mb-0 pb-2 border-b border-primary-default flex-grow',
};
var DATE_FORMAT = 'yyyy-MM-dd hh:mm:ss';
var schema = yup.object({
    customerCode: yup.number().required('cannot be blank'),
    quantityRequired: yup.number().required('cannot be blank'),
    expressDelivery: yup.boolean(),
    customerOrderRef: yup.string().required('cannot be blank'),
    productCode: yup.string().required('cannot be blank'),
    backgroundTapeColour: yup.string().required('cannot be blank'),
    foregroundTextColour: yup.string().required('cannot be blank'),
    line1Text: yup.string(),
    line2Text: yup.string(),
    line3Text: yup.string(),
    line4Text: yup.string(),
    line5Text: yup.string(),
    line6Text: yup.string(),
    line1StyleCode: yup.string(),
    line2StyleCode: yup.string(),
    line3StyleCode: yup.string(),
    line4StyleCode: yup.string(),
    line5StyleCode: yup.string(),
    line6StyleCode: yup.string(),
    deliveryName: yup.string(),
    deliveryAddressLine1: yup.string().required('cannot be blank'),
    deliveryAddressLine2: yup.string(),
    deliveryAddressLine3: yup.string(),
    deliveryAddressLine4: yup.string(),
    deliveryCountry: yup.string(),
    deliveryPostcode: yup.string(),
    motifCode: yup.string(),
    emailAddress: yup.string().email(),
    deliveryEstimate: yup.string().required('cannot be blank'),
    importedAt: yup.string(),
});
export var OrderForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle;
    var _b = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    }), handleSubmit = _b.handleSubmit, register = _b.register, formErrors = _b.formState.errors, setError = _b.setError, setValue = _b.setValue, reset = _b.reset, watch = _b.watch;
    var deliveryEstimate = watch('deliveryEstimate');
    useDefaultValueListener(defaultValues, reset);
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var field = useRef(null);
    useEffect(function () {
        var _a;
        (_a = field.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    var setDeliveryEstimate = function (date) {
        setValue('deliveryEstimate', date, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };
    return (React.createElement("form", { onSubmit: handleFormSubmit, className: "relative" },
        React.createElement(Fields, { register: register, errors: formErrors, grow: true, className: "pb-2" },
            React.createElement("h3", { className: styles.header }, "Order Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "customerCode", label: "Customer Code", placeholder: "i.e. 4760", type: "number", className: "w-full" }),
                React.createElement(TextField, { name: "customerOrderRef", label: "Cust. Order Ref", placeholder: "i.e. 1xx-336xxxx-494xxx", className: "w-full" })),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "productCode", label: "Product Code", placeholder: "i.e. AB-01-72", className: "w-full" }),
                React.createElement(TextField, { name: "quantityRequired", label: "Quanity Required", type: "number", className: "w-full" })),
            React.createElement("h3", { className: styles.header }, "Style Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "backgroundTapeColour", label: "Background Tape Colour", type: "text", className: "w-full" }),
                React.createElement(TextField, { name: "foregroundTextColour", label: "Foreground Tape Colour", type: "text", className: "w-full" }),
                React.createElement(TextField, { name: "motifCode", label: "Motif Code", type: "text", className: "w-full" })),
            React.createElement("h3", { className: styles.header }, "Line 1 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line1StyleCode", label: "Line 1 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line1Text", label: "Line 1 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Line 2 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line2StyleCode", label: "Line 2 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line2Text", label: "Line 2 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Line 3 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line3StyleCode", label: "Line 3 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line3Text", label: "Line 3 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Line 4 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line4StyleCode", label: "Line 4 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line4Text", label: "Line 4 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Line 5 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line5StyleCode", label: "Line 5 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line5Text", label: "Line 5 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Line 6 Details"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "line6StyleCode", label: "Line 6 Style", placeholder: "i.e. Font 7", className: "w-full" }),
                React.createElement(TextField, { name: "line6Text", label: "Line 6 Text", placeholder: "i.e. Made with Love", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Delivery Method"),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "deliveryMethod", label: "Delivery Method", placeholder: "i.e. FreeEconomy", className: "w-full" }),
                React.createElement(DateField, { name: "deliveryEstimateField", label: "Delivery Estimate", placeholder: "MM-DD-YYYY", className: "w-full", dates: deliveryEstimate
                        ? [DateTime.fromISO(deliveryEstimate)]
                        : undefined, onDateChange: function (value) { return setDeliveryEstimate(value[0].toISODate()); } }),
                React.createElement(TextField, { name: "expressDelivery", label: "Express Delivery", className: "w-full" })),
            React.createElement("input", { type: "hidden", name: "deliveryEstimate" }),
            React.createElement("h3", { className: styles.header }, "Delivery Details"),
            React.createElement(TextField, { name: "deliveryName", label: "Delivery Name", placeholder: "i.e. Jane Appleseed", className: "w-full", privateField: true }),
            React.createElement(TextField, { name: "deliveryAddressLine1", label: "Delivery Address", placeholder: "12345 e. Main St.", className: "w-full", privateField: true }),
            React.createElement(TextField, { name: "deliveryAddressLine2", label: "Delivery Address (line 2)", className: "w-full", privateField: true }),
            React.createElement(TextField, { name: "deliveryAddressLine3", label: "Delivery Address (line 3)", className: "w-full", privateField: true }),
            React.createElement(TextField, { name: "deliveryAddressLine4", label: "Delivery Address (line 4)", className: "w-full", privateField: true }),
            React.createElement(Fields, { nested: true, grow: true },
                React.createElement(TextField, { name: "deliveryPostcode", label: "Delivery Post Code", placeholder: "i.e. 97214-2178", className: "w-full", privateField: true }),
                React.createElement(TextField, { name: "deliveryCountry", label: "Delivery Country", placeholder: "i.e. United States", className: "w-full", privateField: true })),
            React.createElement("h3", { className: styles.header }, "Contact Details"),
            React.createElement(TextField, { name: "emailAddress", label: "Email", placeholder: "Email Address", className: "w-full", privateField: true }),
            React.createElement("h3", { className: styles.header }, "Internal Details"),
            React.createElement(TextField, { name: "importedAt", label: "Date Imported", placeholder: "YYYY-MM-DD", className: "w-full", disabled: true }),
            React.createElement(ErrorList, { errors: formErrors }),
            React.createElement(Button, { type: "submit", className: "mt-2 w-full" }, submitTitle !== null && submitTitle !== void 0 ? submitTitle : 'Save Order')),
        loading ? React.createElement(AbsoluteOverlay, null) : null));
};
