import React, { useMemo } from 'react'
import Profile from './Profile'
import { NavBar } from './NavBar'
import { useDrawer } from './useDrawer'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useFetchUsersQuery, User } from 'api-client'
import { Table, Column } from './Table'
import { useManagedTableState } from './useTableState'
import { parseTimestamp } from './helpers'

const USERS_TABLE_ID = 'users'

const fakeResult: User = {
  user_id: '------',
  email: '------',
  last_ip: '----',
  last_login: '----',
}

export const Users: React.FC = () => {
  const { toggleDrawer } = useDrawer()

  const { currentTableState, updatePagination } = useManagedTableState({
    tableId: USERS_TABLE_ID,
  })

  const { data, loading } = useFetchUsersQuery({
    variables: {
      pageInput: {
        page: currentTableState.page,
        pageSize: currentTableState.pageSize,
      },
    },
    fetchPolicy: 'network-only',
  })

  const results = data?.users?.users ?? []
  const pageCount = data?.users?.pageInfo?.pages ?? 0
  const canNextPage = data?.users?.pageInfo?.hasNextPage ?? false
  const canPreviousPage = data?.users?.pageInfo?.hasPreviousPage ?? false

  updatePagination({
    pageCount,
    canNextPage,
    canPreviousPage,
  })

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Logins',
        accessor: 'logins_count',
      },
      {
        Header: 'Last IP',
        accessor: 'last_ip',
      },
      {
        Header: 'Last Login',
        accessor: 'last_login',
      },
    ],
    []
  )

  const navigate = useNavigate()
  const onEdit = (id: string) => {
    navigate(`${id}`)
  }

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <NavBar title="Users" onMenuToggle={toggleDrawer}>
        <Profile className="my-auto" />
      </NavBar>
      <Table
        data={
          !loading
            ? results.map(result => ({
                ...result,
                last_login: parseTimestamp(result?.last_login),
                role: result?.roles?.map(r => r?.name),
                id: result?.user_id,
              }))
            : [...Array(currentTableState.pageSize)].map(() => fakeResult)
        }
        columns={columns}
        controlledPageCount={pageCount}
        loading={loading}
        tableId={USERS_TABLE_ID}
        onEdit={onEdit}
      />
    </>
  )
}
