import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';
import { humanize } from 'utils';
var LIST_STYLES = 'my-2 border border-red-400 p-2 rounded flex flex-col';
var ITEM_STYLES = 'flex font-body text-red-600 text-sm';
var NAME_STYLES = 'font-semibold';
export var ErrorList = function (_a) {
    var className = _a.className, _b = _a.errors, errors = _b === void 0 ? {} : _b;
    var keys = Object.keys(errors !== null && errors !== void 0 ? errors : {});
    return keys.length ? (React.createElement("ul", { className: clsx(LIST_STYLES, className) }, keys.map(function (k) {
        var _a;
        return (React.createElement("li", { key: k, className: ITEM_STYLES },
            React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, size: "sm", className: "my-auto mr-2 text-red-400" }),
            React.createElement("strong", { className: NAME_STYLES },
                humanize(k),
                "\u00A0"),
            ' ', (_a = errors[k]) === null || _a === void 0 ? void 0 :
            _a.message));
    }))) : null;
};
