import {atom, useRecoilState} from "recoil"

interface DrawerState {
  open: boolean
}

const drawerState = atom<DrawerState>({
  key: 'drawerState',
  default: {open: false},
});

export const useDrawer = () => {
  const [{open}, setDrawerState] = useRecoilState<DrawerState>(drawerState)
  return {
    isOpen: open,
    toggleDrawer: () => setDrawerState({ open: !open}),
    openDrawer: () => setDrawerState({ open: true}),
    closeDrawer: () => setDrawerState({ open: false}),
  }
}
